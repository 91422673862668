export const photoExtention = {

  methods: {
    putPhotoExtention(photos) {
      let photosExtention = photos.map(function (item) {
        return {
          description: item.description,
          idLocation: item.idLocation,
          src: item.src + '.webp',
          thumb: item.thumb + '.webp',
          title: item.title
        }
      });
      return photosExtention;
    }
  }
}
