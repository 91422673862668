import { render, staticRenderFns } from "./insulfilm-privacidade-janela.vue?vue&type=template&id=b78c7826&scoped=true&"
import script from "./insulfilm-privacidade-janela.vue?vue&type=script&lang=js&"
export * from "./insulfilm-privacidade-janela.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b78c7826",
  null
  
)

export default component.exports